import $ from 'jquery'

$('#audio-link-the-gift').click(function () {
	var audio = $('#source-audio-gift').get(0)
	var element = $(this)

	if (audio.paused) {
		audio.play()
		$(this).find('i').removeClass('fa-play')
		$(this).find('i').addClass('fa-pause')
	} else {
		audio.pause()
		audio.currentTime = 0
		$(this).find('i').removeClass('fa-pause')
		$(this).find('i').addClass('fa-play')
	}

	audio.addEventListener('ended', function () {
		audio.play()
		// audio.currentTime = 0
		// element.find('i').removeClass('fa-pause')
		// element.find('i').addClass('fa-play')
	})
})

$('#audio-link-silent-night').click(function () {
	var audio = $('#source-audio-silent').get(0)
	var element = $(this)

	if (audio.paused) {
		audio.play()
		$(this).find('i').removeClass('fa-play')
		$(this).find('i').addClass('fa-pause')
	} else {
		audio.pause()
		audio.currentTime = 0
		$(this).find('i').removeClass('fa-pause')
		$(this).find('i').addClass('fa-play')
	}

	audio.addEventListener('ended', function () {
		audio.play()
		// audio.currentTime = 0
		// element.find('i').removeClass('fa-pause')
		// element.find('i').addClass('fa-play')
	})
})

$('#audio-link-our-rescuer').click(function () {
	var audio = $('#source-audio-rescuer').get(0)
	var element = $(this)

	if (audio.paused) {
		audio.play()
		$(this).find('i').removeClass('fa-play')
		$(this).find('i').addClass('fa-pause')
	} else {
		audio.pause()
		audio.currentTime = 0
		$(this).find('i').removeClass('fa-pause')
		$(this).find('i').addClass('fa-play')
	}

	audio.addEventListener('ended', function () {
		audio.play()
		// audio.currentTime = 0
		// element.find('i').removeClass('fa-pause')
		// element.find('i').addClass('fa-play')
	})
})

$('#audio-link-fire-crackle').click(function () {
	var audio = $('#source-audio-fire').get(0)
	var element = $(this)

	if (audio.paused) {
		audio.play()
		$(this).find('i').removeClass('fa-play')
		$(this).find('i').addClass('fa-pause')
	} else {
		audio.pause()
		audio.currentTime = 0
		$(this).find('i').removeClass('fa-pause')
		$(this).find('i').addClass('fa-play')
	}

	audio.addEventListener('ended', function () {
		audio.play()
		// audio.currentTime = 0
		// element.find('i').removeClass('fa-pause')
		// element.find('i').addClass('fa-play')
	})
})

$('#audio-link-asmr').click(function () {
	var audio = $('#source-audio-asmr').get(0)
	var element = $(this)

	if (audio.paused) {
		audio.play()
		$(this).find('i').removeClass('fa-play')
		$(this).find('i').addClass('fa-pause')
	} else {
		audio.pause()
		audio.currentTime = 0
		$(this).find('i').removeClass('fa-pause')
		$(this).find('i').addClass('fa-play')
	}

	audio.addEventListener('ended', function () {
		audio.play()
		// audio.currentTime = 0
		// element.find('i').removeClass('fa-pause')
		// element.find('i').addClass('fa-play')
	})
})
