import $ from 'jquery'

$('.button-fireplace').click(function () {
	var closeItems = $('#home-slider, .menu-text, .slider-buttons, #cookie-player')
	var showItems = $('#fire-player')
	var menuItems = $('.main-menu, .lock-up')

	$('.toggle-fireplace').prop('checked', true)

	if (closeItems.hasClass('visible')) {
		closeItems.removeClass('visible')
	} else {
		closeItems.addClass('hidden')
	}

	if (showItems.hasClass('hidden')) {
		showItems.removeClass('hidden')
	} else {
		showItems.addClass('visible')
	}

	if (menuItems.hasClass('default')) {
		menuItems.removeClass('default')
	} else {
		menuItems.addClass('full-screen')
	}

})

$('.button-cookies').click(function () {
	var closeItems = $('#home-slider, .menu-text, .slider-buttons, #fire-player')
	var showItems = $('#cookies-player')
	var menuItems = $('.main-menu, .lock-uo')

	$('.toggle-cookies').prop('checked', true)

	if (closeItems.hasClass('visible')) {
		closeItems.removeClass('visible')
	} else {
		closeItems.addClass('hidden')
	}

	if (showItems.hasClass('hidden')) {
		showItems.removeClass('hidden')
	} else {
		showItems.addClass('visible')
	}

	if (menuItems.hasClass('default')) {
		menuItems.removeClass('default')
	} else {
		menuItems.addClass('full-screen')
	}

})

$('.home-logo').click(function () {
	var closeItems = $('#home-slider, .menu-text, .slider-buttons ')
	var showItems = $('#cookies-player, #fire-player')
	var menuItems = $('.main-menu')
	var buttonResets = $('.toggle-fireplace, .toggle-cookies')

	if (closeItems.hasClass('hidden')) {
		closeItems.removeClass('hidden')
	} else {
		closeItems.addClass('visible')
	}

	if (showItems.hasClass('visible')) {
		showItems.removeClass('visible')
	} else {
		showItems.addClass('hidden')
	}

	if (menuItems.hasClass('full-screen')) {
		menuItems.removeClass('full-screen')
	} else {
		menuItems.addClass('default')
	}

	buttonResets.attr('checked', false)
})


$('.toggle-fireplace').click(function () {
	var closeItems = $('#cookies-player')
	var showItems = $('#fire-player')

	if (closeItems.hasClass('visible')) {
		closeItems.removeClass('visible')
	} else {
		closeItems.addClass('hidden')
	}

	if (showItems.hasClass('hidden')) {
		showItems.removeClass('hidden')
	} else {
		showItems.addClass('visible')
	}

})

$('.toggle-cookies').click(function () {
	var closeItems = $('#fire-player')
	var showItems = $('#cookies-player')

	if (closeItems.hasClass('visible')) {
		closeItems.removeClass('visible')
	} else {
		closeItems.addClass('hidden')
	}

	if (showItems.hasClass('hidden')) {
		showItems.removeClass('hidden')
	} else {
		showItems.addClass('visible')
	}

})

$('.av-button').click(function () {
	var targetItem = $('.audio-video-chooser-container')

	if (targetItem.hasClass('open')) {
		targetItem.removeClass('open')
	} else {
		targetItem.addClass('open')
	}


})
